<template>
	<v-container fluid  style="padding:0px;" :class="displaySizeClass">
		<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
		<v-row class="text-center mt-6 mx-2"  v-if="dataReady" >
			<v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="orders.length>0">
				<v-card>
					<v-card-title>
						<h1>{{recentOrdersLabel[languageId]}}</h1>
					</v-card-title>
					<v-card-text  v-if="showOrder">
						<v-row v-for="(order, index1) in orders" :key="index1" style="border-bottom:inset 1px lightgray;" :style="index1>0?'margin-top:12px':''">
							<v-col cols="12">
								<h2>{{orderIdLabel[languageId]}}: {{order.id}}</h2>
							</v-col>
							<v-col cols="12" v-for="(item, index) in order.orderDetails" :key="index">
								<v-row no-gutters >
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-container fluid class="fill-height"  style="max-height:208px; padding:4px;">
											<v-img :src="require(`../assets/${getImage(item.title)}`)" max-height="200" contain ></v-img>
										</v-container>
									</v-col>
									<v-col cols="12"  sm="8" md="8" lg="8" xl="8" style="text-align:left;">
										<h3 class="mb-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}/${baseThemeUrl[languageId]}/`+item.title.split('|')[languageId])">{{item.title.split("|")[languageId]}}</h3>
										<v-row no-gutters>
											<v-col cols="5">{{priceLabel[languageId]}}</v-col>
											<v-col cols="7">$ {{item.unitPrice.toLocaleString()}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{sizeLabel[languageId]}} ({{sizeHint[languageId]}})</v-col>
											<v-col cols="7">{{artPieceSizes(item.size)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{printedMediumLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesPrintedMediums(item.printedMedium)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{frameLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesFrames(item.frame)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{linerLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesLiners(item.liner)}}</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="pending.length>0">
				<v-card>
					<v-card-title>
						<h1>{{failedOrdersLabel[languageId]}}</h1>
					</v-card-title>

					<v-card-text  v-if="showPending">
						<v-row v-for="(order, index1) in pending" :key="index1" style="border-bottom:inset 1px lightgray;" :style="index1>0?'margin-top:12px':''">
							<v-col cols="12">
								<h2>{{orderIdLabel[languageId]}}: {{order.id}}</h2>
							</v-col>
							<v-col cols="12" v-for="(item, index) in order.orderDetails" :key="index">
								<v-row no-gutters >
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-container fluid class="fill-height"  style="max-height:208px; padding:4px;">
											<v-img :src="require(`../assets/${getImage(item.title)}`)" max-height="200" contain ></v-img>
										</v-container>
									</v-col>
									<v-col cols="12"  sm="8" md="8" lg="8" xl="8" style="text-align:left;">
										<h3 class="mb-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}/${baseThemeUrl[languageId]}/`+item.title.split('|')[languageId])">{{item.title.split("|")[languageId]}}</h3>
										<v-row no-gutters>
											<v-col cols="5">{{priceLabel[languageId]}}</v-col>
											<v-col cols="7">$ {{item.unitPrice.toLocaleString()}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{sizeLabel[languageId]}} ({{sizeHint[languageId]}})</v-col>
											<v-col cols="7">{{artPieceSizes(item.size)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{printedMediumLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesPrintedMediums(item.printedMedium)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{frameLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesFrames(item.frame)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{linerLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesLiners(item.liner)}}</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" sm="12" md="6" lg="4" xl="4" v-if="saved.length>0">
				<v-card>
					<v-card-title>
						<h1>{{savedOrdersLabel[languageId]}}</h1>
					</v-card-title>

					<v-card-text v-if="showSaved">
						<v-row v-for="(order, index1) in saved" :key="index1" style="border-bottom:inset 1px lightgray;" :style="index1>0?'margin-top:12px':''">
							<v-col cols="12">
								<h2>{{orderIdLabel[languageId]}}: {{order.id}}</h2>
							</v-col>
							<v-col cols="12" v-for="(item, index) in order.orderDetails" :key="index">
								<v-row no-gutters >
									<v-col cols="12" sm="4" md="4" lg="4" xl="4">
										<v-container fluid class="fill-height"  style="max-height:208px; padding:4px;">
											<v-img :src="require(`../assets/${getImage(item.title)}`)" max-height="200" contain ></v-img>
										</v-container>
									</v-col>
									<v-col cols="12"  sm="8" md="8" lg="8" xl="8" style="text-align:left;">
										<h3 class="mb-2" @click="$router.push(`/${baseArtPiecesUrl[languageId]}/${baseThemeUrl[languageId]}/`+item.title.split('|')[languageId])">{{item.title.split("|")[languageId]}}</h3>
										<v-row no-gutters>
											<v-col cols="5">{{priceLabel[languageId]}}</v-col>
											<v-col cols="7">$ {{item.unitPrice.toLocaleString()}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{sizeLabel[languageId]}} ({{sizeHint[languageId]}})</v-col>
											<v-col cols="7">{{artPieceSizes(item.size)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{printedMediumLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesPrintedMediums(item.printedMedium)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{frameLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesFrames(item.frame)}}</v-col>
										</v-row>
										<v-row no-gutters>
											<v-col cols="5">{{linerLabel[languageId]}}</v-col>
											<v-col cols="7">{{artPiecesLiners(item.liner)}}</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
const baseURL = process.env.VUE_APP_BASE_URL_API;
	export default {
		name: 'OrderStatus',
		components: { },
		computed:{
            ...mapState("shoppingCart", 
				[
					"artPieces", "images", "size", "sizePortrait", "printedMedium", "frame", "liner",  "artPiecesOk", "propertiesOk", "app_token", "buyAsGuest", "languageId", "metricSystemId",
					"priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel", "sizeHint","baseArtPiecesUrl","baseThemeUrl",
				]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
            dataReady(){
                return this.artPiecesOk && this.propertiesOk;
            }, 
		},
		data: () => ({
			pageTitle:['Estado de Ordenes Karlus Morales','Order Status Karlus Morales'],
			pageMetaTitle:['Estado de Ordenes Karlus Morales','Order Status Karlus Morales'],
			pageDescription:['Página con información del estado de las ordenes del usuario en Karlus Morales','Page with information on the status of users orders of Karlus Morales'],
            pageKeywords:['Estado de Ordenes','Order Status'],
            metaImage:'',
			recentOrdersLabel:["Ordenes recientes","Recent orders"],
			failedOrdersLabel:["Ordenes con pago fallido", "Orders with failed payment"],
			orderIdLabel:["Id de la orden","Order id"],
			savedOrdersLabel:["Ordenes para más tarde","Saved orders"],

			showOrder:false,
			showPending:false,
			showSaved:false,
			orders:[],
			pending:[],
			saved:[]
		}),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		mounted () { 
			if(this.$route.path.split("/")[2] == "ok"){
                this.setMutation({with:{
					address:'',
					city:'',
					state:'',
					country:'',
					totalValue:null,
					taxesValue:0,
					shippingValue:null,
					orderDetails:[],
				}, property:'order'});
                this.setMutation({with:[], property:'shoppingCart'});
                this.setMutation({with:false, property:'checkoutClick'});
                this.setMutation({with:null, property:'totalAmount'});
				this.setMutation({with:null, property:'orderId'});
			}

			this.getOrders();
		},
		methods: { 
            ...mapActions("shoppingCart", ["removeFromCart", "setMutation", "setOrders"]),
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				if(artPiece == undefined) return 'KarlusMorales_Imagotype_Light.svg'
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
            artPieceSizes(sizes){
                return this.size.filter(x=>sizes.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.metricSystemId]}})[0].name
            },
            artPiecesPrintedMediums(printedMediums){
                return this.printedMedium.filter(x=>printedMediums.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})[0].name
            },
            artPiecesFrames(frames){
                return this.frame.filter(x=>frames.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})[0].name
            },
            artPiecesLiners(liners){
                return this.liner.filter(x=>liners.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})[0].name
            },
			getOrders(){
				if(this.buyAsGuest)
					return;
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.app_token}`;
                axios.get(baseURL + 'Orders')
                    .then(res => {
						if(res.status==200){
							for(var i = 0; i < res.data.length; i++){
								if(res.data[i].keys==undefined){
									if(res.data[i].values != undefined){
										this.saved=res.data[i].values;
										this.showSaved = true;
									}
									else
										this.saved= null;
								}
								if(res.data[i].keys==true){
									if(res.data[i].values != undefined){
										this.orders=res.data[i].values;
										this.showOrder = true;
									}
									else
										this.orders= null;
								}
								if(res.data[i].keys==false){
									if(res.data[i].values != undefined){
										this.pending=res.data[i].values;
										this.showPending = true;
									}
									else
										this.pending= null;
								}
							}
						}
                    })
                    .catch(error => { 
                        if(error.response.status==401){
                            this.setMutation({with:null, property:'app_token'});
                        }
                        console.log('getOrders',error);
                    });
			}
		},
	}
</script>

<style scoped>
h3:hover{
    text-decoration:underline;
    cursor: pointer;
}
p{
    margin:0px;
}
</style>